const EnumAposentadoriaEspecial = Object.freeze([
    {
        value: 'NAO_ENSEJADOR_APOSENTADORIA_ESPECIAL',
        label: 'Não ensejador de aposentadoria especial'
    },
    {
        value: 'APOSENTADORIA_ESPECIAL_15_ANOS',
        label: 'Aposentadoria especial (15 anos)'
    },
    {
        value: 'APOSENTADORIA_ESPECIAL_20_ANOS',
        label: 'Aposentadoria especial (20 anos)'
    },
    {
        value: 'APOSENTADORIA_ESPECIAL_25_ANOS',
        label: 'Aposentadoria especial (25 anos)'
    }]);
export default EnumAposentadoriaEspecial;
