<template>
    <div class="col-12" v-if="podeVisualizar">
        <AppLoadingWrapper v-if="loading" />
        <DataTable
            v-if="!loading"
            ref="table"
            v-model:expandedRows="expandedRows"
            :value="form"
            :row-hover="true"
            class="p-datatable-sm"
            responsiveLayout="stack"
            breakpoint="640px"
            dataKey="id"
        >
            <template #empty>
                <div class="flex flex-column justify-content-center align-items-center p-2">
                    <span style="font-size: 2rem; color: var(--gray-500)">Nenhum dado encontrado.</span>
                </div>
            </template>
            <Column :expander="true" style="width: 50px" />
            <Column>
                <template #body="{ data }">
                    <h5>{{ enumTipoAplicacaoLaudoLabel.get(enumTipoAplicacaoLaudo[data.tipo]) }}</h5>
                </template>
            </Column>

            <template #expansion="slotProps">
                <div>
                    <div class="field col-4" v-if="slotProps.data.tipo == enumTipoAplicacaoLaudo.LTCAT">
                        <div class="mb-1 field">
                            <label for="aposentadoriaEspecial">Aposentadoria Especial</label>
                        </div>
                        <Dropdown
                            id="aposentadoriaEspecial"
                            v-model="slotProps.data.resposta"
                            :options="enumAposentadoriaEspecial"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione..."
                            class="w-full"
                            @change="salvarDadosLaudo(slotProps.data.id)"
                        ></Dropdown>
                    </div>
                    <div class="field col-4" v-if="slotProps.data.tipo == enumTipoAplicacaoLaudo.LTI">
                        <div class="mb-1 field">
                            <label for="insalubridade">Insalubridade</label>
                        </div>
                        <Dropdown
                            id="insalubridade"
                            v-model="slotProps.data.resposta"
                            :options="enumInsalubridade"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione..."
                            class="w-full"
                            @change="salvarDadosLaudo(slotProps.data.id)"
                        ></Dropdown>
                    </div>
                    <div class="field col-4" v-if="slotProps.data.tipo == enumTipoAplicacaoLaudo.LTP">
                        <div class="mb-1 field">
                            <label for="periculosidade">Periculosidade</label>
                        </div>
                        <Dropdown
                            id="periculosidade"
                            v-model="slotProps.data.resposta"
                            :options="enumPericulosidade"
                            optionValue="value"
                            optionLabel="label"
                            placeholder="Selecione..."
                            class="w-full"
                            @change="salvarDadosLaudo(slotProps.data.id)"
                        ></Dropdown>
                    </div>
                    <div class="field col-4">
                        <div class="mb-1 field">
                            <label for="parecerTecnico">Parecer Técnico</label>
                        </div>
                        <InputText
                            id="parecerTecnico"
                            required="true"
                            v-model="slotProps.data.parecerTecnico"
                            autofocus
                            autocomplete="off"
                            class="w-full"
                            @change="salvarDadosLaudo(slotProps.data.id)"
                        />
                    </div>
                    <div class="field col-4">
                        <div class="mb-1 field">
                            <label for="nome">Data Conclusão</label>
                        </div>
                        <Calendar
                            v-model="slotProps.data.dataConclusao"
                            class="w-full"
                            dateFormat="dd/mm/yy"
                            :showIcon="true"
                            @blur="salvarDadosLaudo(slotProps.data.id)"
                        />
                    </div>
                </div>
            </template>
            <template #footer>
                <Card class="mt-3">
                    <template #title>
                        <h4>Conclusão etapa</h4>
                    </template>
                    <template #content>
                        <div class="flex flex-row">
                            <div class="flex flex-column col-5">
                                <label for="responsavelSesmtElaboracao">Responsável pela elaboração</label>
                                <DropdownProfissionalSesmt
                                    v-model="responsavelElaboracao"
                                    @change="salvarResponsavelElaboracao"
                                    id="responsavelSesmtElaboracao"
                                />
                            </div>
                            <div class="flex flex-column col-4">
                                <label for="responsavelSesmtTecnico">Responsável técnico</label>
                                <DropdownProfissionalSesmt
                                    v-model="responsavelTecnico"
                                    @change="salvarResponsavelTecnico"
                                    id="responsavelSesmtTecnico"
                                />
                            </div>
                        </div>
                        <div class="flex justify-content-end">
                            <Button :loading="loadingConcluir" class="btn-concluir bg-green-600" label="Concluir etapa" @click="concluir" />
                        </div>
                    </template>
                </Card>
            </template>
        </DataTable>
    </div>
</template>

<script>
import eventBus from '../../../../event-bus';
import { getClientBase } from '../../../../services/http';
import EnumTipoAplicacaoLaudo, { EnumTipoAplicacaoLaudoLabel } from '../../../../enums/EnumTipoAplicacaoLaudo';
import EnumAposentadoriaEspecial from '../../../../enums/EnumAposentadoriaEspecial';
import EnumInsalubridade from '../../../../enums/EnumInsalubridade';
import EnumPericulosidade from '../../../../enums/EnumPericulosidade';
import BaseService from '../../../../services/BaseService';
import moment from 'moment';
import { showError, showSuccess } from '../../../../utils/Toast';
import DropdownProfissionalSesmt from '../../../profissionais-sesmt/components/DropdownProfissionalSesmt.vue';
import InventarioRiscoService from '../../services/InventarioRiscoService';
export default {
    components: { DropdownProfissionalSesmt },
    data() {
        return {
            expandedRows: [],
            form: null,
            responsavelTecnico: null,
            responsavelElaboracao: null,
            aplicacaoLaudoId: null,
            loading: false,
            habilitaLaudos: false,
            podeVisualizar: false,
            loadingConcluir: false,
            enumTipoAplicacaoLaudo: EnumTipoAplicacaoLaudo,
            enumTipoAplicacaoLaudoLabel: EnumTipoAplicacaoLaudoLabel,
            enumAposentadoriaEspecial: EnumAposentadoriaEspecial,
            enumInsalubridade: EnumInsalubridade,
            enumPericulosidade: EnumPericulosidade
        };
    },
    async created() {
        const { data } = await getClientBase().get(`step-aplicacao/inventario-risco/${this.$route.params.id}/etapa/${this.$route.name}`);
        if (data.podeVisualizar) {
            this.form = data.entidade;
            this.aplicacaoLaudoId = data.entidade[0]?.aplicacaoLaudoId;
            this.form.forEach((element) => {
                element.dataConclusao = this.getDateWithTimeZoneLocal(element.dataConclusao);
            });
        }
        this.podeVisualizar = data.podeVisualizar;
        eventBus.emit('disabledButtonIniciar', data.podeVisualizar);
    },
    mounted() {
        this.service = new BaseService('aplicacao-laudo-resposta');
        this.serviceAplicacaoLaudo = new BaseService('aplicacao-laudo');
        this.inventarioRiscoService = new InventarioRiscoService();

        eventBus.on('onLoadLaudoScreen', (aplicacaoLaudoId) => {
            this.setaDados(aplicacaoLaudoId);
        });
    },
    methods: {
        async setaDados(aplicacaoLaudoId) {
            const { data } = await getClientBase().get(`aplicacao-laudo-resposta/aplicacao-laudo/${aplicacaoLaudoId}`);
            this.form = data;
            this.aplicacaoLaudoId = data[0]?.aplicacaoLaudoId;
            this.podeVisualizar = true;
        },
        salvarDadosLaudo(aplicacaoLaudoRespostaId) {
            const entity = this.form.find((p) => p.id == aplicacaoLaudoRespostaId);
            this.service.save(entity);
        },
        getDateWithTimeZoneLocal(data) {
            return data ? this.$filters.formatDateOnly(moment.tz(data, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate()) : null;
        },
        async salvarResponsavelElaboracao() {
            const responsavelElaboracaoId = this.responsavelElaboracao.id;
            const data = {
                id: this.aplicacaoLaudoId,
                responsavelElaboracaoId
            };
            try {
                await this.serviceAplicacaoLaudo.save({
                    ...data
                });
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async salvarResponsavelTecnico() {
            const responsavelTecnicoId = this.responsavelTecnico.id;
            const data = {
                id: this.aplicacaoLaudoId,
                responsavelTecnicoId
            };
            try {
                await this.serviceAplicacaoLaudo.save({
                    ...data
                });
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        async concluir() {
            try {
                this.loadingConcluir = true;
                const formData = {
                    id: this.aplicacaoLaudoId,
                    aplicacaoLaudoRespostas: this.form,
                    responsavelTecnico: this.responsavelTecnico,
                    responsavelElaboracao: this.responsavelElaboracao
                }
                await getClientBase().post(`aplicacao-laudo/concluir-etapa`, formData);
                this.loadingConcluir = false;
                showSuccess(this.$toast, 'Etapa concluída com sucesso!');
                eventBus.emit('onReloadSteps');
            } catch (error) {
                this.loadingConcluir = false;
                showError(this.$toast, error);
            }
        }
    }
};
</script>