const EnumPericulosidade = Object.freeze([
    {
        value: 'NAO',
        label: 'Não'
    },
    {
        value: 'SIM',
        label: 'Sim'
    }
   ]);
export default EnumPericulosidade;
