<template>
  <Grid />
</template>

<script>
import Grid from "../aplicacao-laudo/Grid.vue"
export default {
    components:{ Grid }

}
</script>

<style>

</style>
