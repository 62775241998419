const EnumTipoAplicacaoLaudo = Object.freeze(
    {
        LTCAT: 'LTCAT',
        LTI: 'LTI',
        LTP: 'LTP'
    });


    export const EnumTipoAplicacaoLaudoLabel = new Map([
        [EnumTipoAplicacaoLaudo.LTCAT, 'Laudo Técnico das Condições do Ambiente de Trabalho'],
        [EnumTipoAplicacaoLaudo.LTI, 'Laudo Técnico de Insalubridade'],
        [EnumTipoAplicacaoLaudo.LTP, 'Laudo Técnico de Periculosidade']
    ])
export default EnumTipoAplicacaoLaudo;
