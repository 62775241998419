import BaseService from "../../../services/BaseService";
import { getClientBase } from '../../../services/http'

export default class InventarioRiscoService extends BaseService {
    constructor() {
        super('inventario-risco');
        this.path = 'inventario-risco';
    }

    findStepsByInventarioRisco(id) {
        return getClientBase().get(`${this.path}/find-steps/${id}`);
    }

    iniciar(id, payload) {
        return getClientBase().post(`${this.path}/iniciar/${id}`, {
            ...payload
        });
    }

    alterarPerfilExposicao(id, payload) {
        return getClientBase().post(`${this.path}/alterar-perfil-exposicao/${id}`, {
            ...payload
        });
    }

    concluirEtapa(id, dataConclusao) {
        return getClientBase().patch(`${this.path}/concluir-etapa/${id}`, {
            dataConclusao
        });
    }

    findByAplicacaoId(id) {
        return getClientBase().get(`${this.path}/aplicacao/${id}`);
    }
}
