const EnumInsalubridade = Object.freeze([
    {
        value: 'NAO',
        label: 'Não'
    },
    {
        value: 'INSALUBRIDADE_MINIMA',
        label: 'Insalubridade Mínima'
    },
    {
        value: 'INSALUBRIDADE_MEDIA',
        label: 'Insalubridade Média'
    },
    {
        value: 'INSALUBRIDADE_MAXIMA',
        label: 'Insalubridade Máxima'
    }]);
export default EnumInsalubridade;
